

























import { Component, Vue, Watch } from "vue-property-decorator";
import UserRoleService from "@/services/UserRoleService";
import UserService from "@/services/UserService";
import ManageUser from "@/models/ManageUser";
import { UserProfilEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    SitesTableModal: () =>
      import("@/components/Shared/Table/SitesTableModal.vue"),
  },
})
export default class ArkemaUsers extends Vue {
  public data: Data = {
    columns: [],
    rows: [],
  };
  private modifiedUsers: Array<ManageUser> = [];
  private url: string = UserService.getTableUrl(
    "arkemaUser",
    this.$i18n.locale
  );

  // Props for Modal on categoy field
  // Sites Modal
  private showSitesModal: Boolean = false;
  private propsSitesModal: any = false;

  // Validate button on modals
  public validate(user: any) {
    (<any>this.$refs.table).modify(user);
    this.showSitesModal = false;
  }

  public test() {
    //console.log('yo')
  }

  public async save() {
    for (let i = 0; i < this.modifiedUsers.length; i++) {
      const modifiedUser = this.modifiedUsers[i];

      if (modifiedUser.firstName == "") {
        this.sendErrorNotification(
          this.$t("arkemaUsers.updateFailed").toString(),
          this.$t("arkemaUsers.propertyFirstNameEmpty").toString()
        );
        return;
      } else if (modifiedUser.lastName == "") {
        this.sendErrorNotification(
          this.$t("arkemaUsers.updateFailed").toString(),
          this.$t("arkemaUsers.propertyLastNameEmpty").toString()
        );
        return;
      } else if (modifiedUser.idSap == "") {
        this.sendErrorNotification(
          this.$t("arkemaUsers.updateFailed").toString(),
          this.$t("arkemaUsers.propertyIdSapEmpty").toString()
        );
        return;
      } else if (modifiedUser.mail == "") {
        this.sendErrorNotification(
          this.$t("arkemaUsers.updateFailed").toString(),
          this.$t("arkemaUsers.propertyMailEmpty").toString()
        );
        return;
      }
    }
    try {
      await UserService.saveUsers(this.modifiedUsers, this.$i18n.locale);
      this.$notify({
        group: "global",
        type: "success",
        title: this.$t("externalUsers.updateSuccess.title").toString(),
        text: this.$t("externalUsers.updateSuccess.description").toString(),
      });
      this.refresh();
    } catch (err) {
      let errMessage = this.$t("arkemaUsers.uknownError").toString();
      if (err.response.status === 400) {
        switch (err.response.data.errorCode) {
          case "duplicateUserLogin":
            errMessage = this.$t("arkemaUsers.duplicateUserLogin").toString();
            break;
          case "duplicateUserMail":
            errMessage = this.$t("arkemaUsers.duplicateUserMail").toString();
            break;
          case "duplicateUserIdSap":
            errMessage = this.$t("arkemaUsers.duplicateUserIdSap").toString();
            break;
          case "idSapNotValid":
            errMessage = this.$t("arkemaUsers.idSapNotValid").toString();
            break;
          case "lastCentralAdministratorEnabled":
            errMessage = this.$t(
              "arkemaUsers.lastCentralAdministratorEnabled"
            ).toString();
            break;
          case "userMustHaveRole":
            errMessage = this.$t("arkemaUsers.userMustHaveRole").toString();
            break;
          case "notEnoughPermissions":
            errMessage = this.$t("arkemaUsers.notEnoughPermissions").toString();
            break;
          case "idRoleRequired":
            errMessage = this.$t("arkemaUsers.roleIsRequired").toString();
            break;
        }
      }
      this.sendErrorNotification(
        this.$t("arkemaUsers.updateFailed").toString(),
        errMessage
      );
    }
  }
  private refresh() {
    this.modifiedUsers = [];
    (<any>this.$refs.table).refreshAPI();
  }
  private sendErrorNotification(title: string, text: string) {
    this.$notify({
      group: "globalError",
      type: "error",
      title: title,
      text: text,
      duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
    });
  }

  // Open modal based on property
  public openModal(props: any) {
    if (props.rowField.property == "sites") {
      this.showSitesModal = true;
      this.propsSitesModal = props;
    }
  }

  public add(): void {
    const newUser = new ManageUser();
    newUser.keyProfil = "arkemaUser";
    (<any>this.$refs.table).updatedRows.unshift(newUser);
    (<any>this.$refs.table).modify(newUser);
  }

  public async loadElements() {
    const roles = await UserRoleService.getRolesByProfil(
      this.$i18n.locale,
      UserProfilEnum.arkemaUser
    );
    this.data.columns = [
      {
        name: "editable",
        sortField: "idSap",
        title: this.$t("arkemaUsers.sapId").toString(),
        property: "idSap",
        length: 8,
      },
      {
        name: "editable",
        sortField: "firstName",
        title: this.$t("arkemaUsers.firstName").toString(),
        property: "firstName",
      },
      {
        name: "editable",
        sortField: "lastName",
        title: this.$t("arkemaUsers.lastName").toString(),
        property: "lastName",
      },
      {
        name: "category",
        sortField: "sites",
        title: this.$t("arkemaUsers.sites").toString(),
        property: "sites",
        display: "translation.name",
      },
      {
        name: "editable",
        sortField: "mail",
        title: this.$t("arkemaUsers.email"),
        property: "mail",
        type: "email",
      },
      {
        name: "dropdown",
        sortField: "nameRole",
        title: this.$t("arkemaUsers.roles").toString(),
        property: "idRole",
        options: roles.data,
        valueField: "role.id",
        textField: "translation.name",
        display: "nameRole",
        default: 0,
      },
      {
        name: "switch",
        sortField: "isEnabled",
        title: this.$t("arkemaUsers.enabled").toString(),
        property: "isEnabled",
      },
    ];
  }
  public mounted() {
    this.loadElements();
  }

  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.loadElements();
    this.url = UserService.getTableUrl("arkemaUser", this.$i18n.locale);
  }
}
